<template>
  <div v-if="getItemError">
    <b-alert
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        Ошибка при получении данных страницы
      </h4>
      <div class="alert-body">
        Не найдена страница с этим идентификатором.
      </div>
    </b-alert>
  </div>
  <b-overlay
    v-else
    :show="loading"
    spinner-variant="primary"
  >
    <app-breadcrumb
      page-title="Настройки страницы"
      :breadcrumb="breadcrumb"
    />
    <b-card>
      <div class="">
        <b-row v-if="error_message">
          <b-col>
            <small
              class="text-danger d-block mb-1"
            >
              {{ error_message }}
            </small>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Активность"
              label-for="is-published"
            >
              <b-form-checkbox
                id="is-published"
                v-model="item.is_published"
                :state="get(validation, 'is_published[0]') ? false : null"
                switch
                :value="true"
                :unchecked-value="false"
              >
                <template v-if="item.is_published">
                  Вкл
                </template>
                <template v-else>
                  Выкл
                </template>
              </b-form-checkbox>
              <small
                v-if="!!get(validation, 'is_published[0]')"
                class="text-danger"
              >
                {{ get(validation, 'is_published[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Название *"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model.trim="item.name"

                placeholder="Введите название"
                :state="get(validation, 'name[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'name[0]')"
                class="text-danger"
              >
                {{ get(validation, 'name[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group

              label="Slug"
              label-for="slug"
              description="Если не заполнен, генерируется автоматически на основе названия. Должен состоять не менее чем из 3 и не более чем из 255 символов (a-z, 0-9, -)."
            >
              <b-form-input
                id="slug"
                v-model.trim="item.slug"
                v-mask="{ regex: '^[a-z0-9]+(?:-[a-z0-9]+)*$', placeholder: '' }"
                placeholder="Slug"
                :state="get(validation, 'slug[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'slug[0]')"
                class="text-danger"
              >
                {{ get(validation, 'slug[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <h4>
              SEO
            </h4>
          </b-col>

          <b-col
            cols="12"
          >
            <seo-fields-card
              :title.sync="item.dev_data.meta.title"
              :description.sync="item.dev_data.meta.description"
              :image.sync="item.dev_data.meta.image"
            />
          </b-col>

        </b-row>
        <b-row class="mt-1 mb-3">
          <b-col>
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="updateItem"
            >
              Сохранить
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BAlert, BButton, BCard, BCol, BFormGroup, BFormInput, BLink, BOverlay, BRow, BTab, BTabs,
  BFormTextarea, BInputGroup, BCardHeader,
  BCardTitle, BTable, BFormCheckbox, BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import get from 'lodash/get'
import {
  getCurrentInstance, ref, onMounted, computed,
} from 'vue'
import AppBreadcrumb from '@/layouts/components/AppBreadcrumb.vue'
import { updatePage as updatePageApi, getPage as getPageApi } from '@/services/main-api/websites/stores/pages'
import useCrudEdit from '@/composables/useCrudEdit'
import { getWebsite as getWebsiteApi } from '@/services/main-api/websites/stores/websites'
import SeoFieldsCard from '@/modules/sites-section/site-store-section/pages/components/SeoFieldsCard.vue'

export default {
  name: 'EditWarehouseView',
  components: {
    SeoFieldsCard,
    BAlert,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    vSelect,
    BTab,
    BTabs,
    BFormCheckbox,
    BFormTextarea,
    BInputGroup,
    BCardHeader,
    BCardTitle,
    BTable,
    BAvatar,
    AppBreadcrumb,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy
    const getItemError = ref(false)

    // BEGIN Site
    const site = ref(null)

    const getSite = async () => {
      try {
        site.value = (await getWebsiteApi({
          id: instance.item.website_id,
        })).data.data
      } catch (e) {
        site.value = null
      }
    }
    // END Site

    const getItem = async () => {
      try {
        const res = await getPageApi({ id: props.id })
        instance.item = res.data.data
        await getSite()
      } catch (e) {
        instance.item = {}
        instance.getItemError = true
      }
    }

    const updateEntity = async () => {
      await updatePageApi({
        id: props.id,
        name: instance.item.name,
        is_published: instance.item.is_published,
        slug: instance.item.slug,
        dev_data: instance.item.dev_data,
      })
    }

    // BEGIN breadcrumb
    const breadcrumb = computed(() => [
      {
        text: 'Сайты',
        to: {
          name: 'sites',
        },
      },
      {
        text: get(site.value, 'name', 'Сайт'),
        to: {
          name: 'sites.store.show',
          params: {
            id: get(site.value, 'id', null),
          },
        },
      },
      {
        text: 'Настройки страницы',
        active: true,
      },
    ])
    // END breadcrumb

    const {
      item,
      loading,
      validation,
      error_message,
      clearForm,
      updateItem,
    } = useCrudEdit({
      item: {
        id: null,
        name: '',
        is_published: false,
        slug: '',
        dev_data: {
          meta: {
            title: '',
            description: '',
            image: '',
          },
        },
      },
      validation: {
        rules: {
          name: 'required',
        },
        customErrorMessages: {
          'required.name': 'Укажите название',
        },
      },
      getItem,
      updateEntity,
      getTextAfterUpdate: () => 'Страница была изменена.',
      runAfterUpdate: async () => {
        await instance.$router.go(-1)
      },
    })

    onMounted(() => {
      if (!instance.$store.getters['workingMode/mode']) {
        instance.$router.push({ name: 'dashboard' })
      }
    })

    return {
      item,
      loading,
      validation,
      error_message,
      clearForm,
      updateItem,

      breadcrumb,

      get,
      getItemError,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
